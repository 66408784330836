import HttpRequest from "@/http/httpClass";
import { defineStore } from "pinia";
import type { Platform } from "./Platform";
import { ref } from "vue";

export const usePlatformsStore = defineStore('platforms', () => {
  const platforms = ref<Platform[]>([])

  const fetchPlatforms = async () => {
    if (!platforms.value.length) {
      const request = new HttpRequest(
        '/platforms',
        'GET',
        true
      )
  
      platforms.value = await request.send()
    }
  }

  const getPlatformFromId = (platformId: number) =>
    platforms.value.find(p => p.id === platformId)
  

  return { platforms, fetchPlatforms, getPlatformFromId }
})