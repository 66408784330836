<template>
  <footer class="footer">
    <PleezLogo class="footer__logo" />
    <p class="footer__text">
      pleez 2024 -
      <button class="footer__terms-and-conditions" @click="handleOpenTermsAndConditions">
        Terms & Conditions
      </button>
    </p>
    <ul class="footer__social-media">
      <li v-for="item in socialMedia">
        <a class="footer__button" :href="item.url">
          <component :is="item.icon" />
        </a>
      </li>
    </ul>
  </footer>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import { socialMedia } from './Footer.config';

export default defineComponent({
  data() {
    return {
      socialMedia,
    };
  },
  methods: {
    handleOpenTermsAndConditions() {
      this.$emit('termsAndConditionsClick');
    },
  },
});
</script>

<style scoped lang="scss">
.footer {
  @include brand-font-m;

  display: grid;
  grid-template-areas: 'logo' 'social-media' 'text';
  justify-items: center;
  align-items: center;

  gap: var(--spacing-xs);

  &__logo {
    grid-area: logo;
  }
  &__text {
    grid-area: text;
  }
  &__terms-and-conditions {
    text-decoration: underline;
    cursor: pointer;
  }
  &__social-media {
    display: flex;
    grid-area: social-media;

    gap: var(--spacing-xs);
  }
  &__button {
    display: flex;
    justify-content: center;
    align-items: center;

    width: var(--spacing-m);
    aspect-ratio: 1;

    border-radius: var(--spacing-3xs);

    background-color: var(--brand-black);
  }

  @include breakpoint-from('tablet') {
    grid-template-areas: 'text logo social-media';

    &__text {
      justify-self: start;
    }
    &__social-media {
      justify-self: flex-end;
    }
  }
}
</style>
