/**
 * Here is defined all layouts of the application.
 */
import { App } from 'vue';
import AuthenticatedLayout from './AuthenticatedLayout.vue';
import NotAuthenticatedLayout from './NotAuthenticatedLayout.vue';

export default {
  install(app: App) {
    // app.component('blank-layout', BlankLayout);
    app.component('authenticated-layout', AuthenticatedLayout);
    app.component('not-authenticated-layout', NotAuthenticatedLayout);
  },
};
