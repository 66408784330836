<template>
  
    <div>
      <TermsAndConditionsComponent
        v-if="showTermsAndConditionModal"
        :terms-and-conditions="termsAndConditions"
        :is-accepted="isTermsAccepter"
        @closeClick="handleCloseClick"
        @termsAgreed="handleTermsAgreed"
      />
      <template v-if="isTermsAccepter">
        <Header @menuClick="handleMobileMenuClick" />

        <main class="content">
          <Sidebar
            :shouldShowOverlay="!isDesktop"
            :shouldShowSideBar="shouldShowSideBar"
            @close="handleMobileOverlayClick"
          />

          <div 
            class="content__body"
            :class="{
              'content__body--single-page': meta?.singlePage,
            }"
          >
            <Breadcrumb class="content__bread-crumbs" />
            <Suspense>
              <router-view class="content__authenticated-layout" />
            </Suspense>
            <Footer
              class="content__footer"
              @termsAndConditionsClick="showTermsAndConditionModal = true"
            />
          </div>
        </main>
      </template>
    </div>  
  
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Header from '@/components/Header/HeaderComponent.vue';
import Footer from '@/components/Footer/FooterComponent.vue';
import Sidebar from '@/components/Sidebar/SidebarComponent.vue';
import Breadcrumb from '@/components/BreadCrumb/BreadCrumbComponent.vue';
import TermsAndConditionsComponent from '@/components/TermsAndConditions/TermsAndConditionsComponent.vue';
import { useFeatureFlagsStore, usePlatformsStore } from '@/store/v2';

import resizeMixin from '@/mixins/resize';
import { viewPort } from '@/utils/constants';
import { PropType } from 'vue';
import { Route } from '@/routes/routesInterface';

export default defineComponent({
  name: 'AuthenticatedLayout',
  props: {
    meta: {
      type: Object as PropType<Route['meta']>,
    }
  },
  components: {
    Header,
    Footer,
    Breadcrumb,
    Sidebar,
    TermsAndConditionsComponent,
  },
  mixins: [resizeMixin],
  beforeMount() {
    if (!this.$session.getSession('pleez-token') && !this.$session.getSession('api-key')) {
      this.$router.push('/');
    }

    window.addEventListener('resize', () => (this.isSideBarVisible = false));
  },
  async mounted() {
    this.termsAndConditions = await this.$store.dispatch('getTermsAndConditions');

    // Setup feature flags store
    const featureFlagStore = useFeatureFlagsStore()
    await featureFlagStore.fetchFeatureFlags()

    // Setup platforms store
    const platformsStore = usePlatformsStore()
    await platformsStore.fetchPlatforms()

    if (!this.termsAndConditions) {
      throw new Error('Could not get terms and conditions.');
    }

    if (this.termsAndConditions.acceptedTerms) {
      return;
    }

    this.showTermsAndConditionModal = true;
  },
  data() {
    return {
      isSideBarVisible: false,
      showTermsAndConditionModal: false,
      termsAndConditions: null,
    };
  },
  computed: {
    shouldShowSideBar() {
      return this.isDesktop || this.isSideBarVisible;
    },
    isDesktop() {
      return this.currentViewPort === viewPort.Desktop;
    },
    isTermsAccepter() {
      return Boolean(this.termsAndConditions?.acceptedTerms);
    },
  },
  methods: {
    handleMobileMenuClick() {
      this.isSideBarVisible = !this.isSideBarVisible;
    },
    handleMobileOverlayClick() {
      this.isSideBarVisible = false;
    },
    handleCloseClick() {
      this.showTermsAndConditionModal = false;
    },
    handleTermsAgreed() {
      this.showTermsAndConditionModal = false;
      this.termsAndConditions.acceptedTerms = true;
    },
  },
});
</script>

<style lang="scss" scoped>
.content {
  --max-content-size: 2000px;
}

.content {
  display: flex;
  flex-direction: column;

  &__body {
    flex: 1;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--spacing-m);


    padding: var(--spacing-s);

    background-color: var(--color-white);

    &--single-page {
      height: calc(100% - 2 * var(--spacing-s));

      @include breakpoint-to('tablet') {
        height: unset;
      }
    }
  }
  &__authenticated-layout,
  &__bread-crumbs {
    flex: 0;
    width: 100%;
    max-width: var(--max-content-size);
  }
  &__authenticated-layout {
    flex: 1;
    width: 100%;
    margin: 0 auto;

    &--single-page {
      max-height: 100%;

      @include breakpoint-to('tablet') {
        max-height: unset;
      }
    }
  }
  &__footer {
    flex: 0;
    width: 100%;
    margin-top: auto;
  }

  @include breakpoint-from('smallDesktop') {
    flex-direction: row;
    flex: 1;

    overflow: auto;

    &__body {
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
}
</style>
