<template>
  <div class="calendar__wrapper">
    <div :class="['calendar', { 'calendar--is-inside-modal': isInsideModal }]" ref="calendar">
      <nav class="calendar__header">
        <ChevronIcon
          class="calendar__header-icon calendar__header-icon--left"
          aria-hidden="true"
          @click="handleChangeMonth('left')"
        />
        <div class="calendar__header-selectors">
          <select v-model="selectedMonth" class="calendar__header-selector">
            <option v-for="(month, index) in months" :value="index">{{ month }}</option>
          </select>
          <select v-model="selectedYear" class="calendar__header-selector">
            <option v-for="year in availableYears" :key="year" :value="year">
              {{ year }}
            </option>
          </select>
        </div>
        <ChevronIcon
          class="calendar__header-icon calendar__header-icon--right"
          aria-hidden="true"
          @click="handleChangeMonth('right')"
        />
      </nav>
      <div class="calendar__body">
        <header
          v-for="(weekDay, index) in parsedDaysOfTheWeek"
          :key="weekDay + index"
          class="calendar__week-day"
        >
          {{ weekDay }}
        </header>
        <button
          v-for="(day, index) in calendar"
          :key="index"
          :class="[
            'calendar__day',
            { 'calendar__day--disabled': !isDateAvailable(day) },
            { 'calendar__day--active': isDaySelected(day) },
            { 'calendar__day--in-range': isDateInOrdersRange(day) },
            { 'calendar__day--has-sales': isDayWithSales(day) },
          ]"
          @click="handleCalendarDayClick(day)"
        >
          {{ showOnlyDayFromDate(day) }}
        </button>
      </div>
      <footer class="calendar__footer">
        <div v-if="isRangeCalendar" class="calendar__footer-presets">
          <button
            v-for="button in buttonPresets"
            class="calendar__footer-button"
            :key="button.label"
            @click="button.event"
          >
            {{ button.label }}
          </button>
        </div>
        <p v-if="isSalesCalendar" class="calendar__footer-legend">
          <span class="calendar__footer-icon">●</span>
          {{ $t('shared.inputs.calendar.legend') }}
        </p>
        <div v-if="isRangeCalendar" class="calendar__footer-actions">
          <button class="calendar__footer-button" @click="() => handleClose(false)">
            {{ $t('shared.cancel') }}
          </button>

          <button
            class="calendar__footer-button calendar__footer-button--primary"
            @click="() => handleClose(true)"
          >
            {{ $t('shared.inputs.calendar.setDate') }}
          </button>
        </div>
        <div v-if="isRangeCalendar && selectedDate1" class="calendar__footer-selected">
          <p>
            {{ `${formatDate(selectedDate1)} - ${selectedDate2 ? formatDate(selectedDate2) : ''}` }}
          </p>
        </div>
      </footer>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import Select from '@/components/Inputs/Select.vue';

import { CalendarData, CalendarProps } from './DatePicker';

export default defineComponent({
  components: {
    Select,
  },
  props: {
    isRangeCalendar: {
      type: Boolean,
      default: false,
    },
    isSalesCalendar: {
      type: Boolean,
      default: false,
    },
    isInsideModal: {
      type: Boolean,
      default: false,
    },
    allowOnlyForward: {
      type: Boolean,
      default: false,
    },
    highlightDates: {
      type: Array as PropType<CalendarProps['highlightDates']>,
      default: () => [],
    },
    selectableRange: {
      type: Object as PropType<CalendarProps['selectableRange']>,
      required: true,
    },
  },
  data() {
    return {
      selectedMonth: new Date().getMonth(),
      selectedYear: new Date().getFullYear(),
      calendar: [],
      daysOfTheWeek: [
        this.$t('shared.daysOfTheWeek.monday'),
        this.$t('shared.daysOfTheWeek.tuesday'),
        this.$t('shared.daysOfTheWeek.wednesday'),
        this.$t('shared.daysOfTheWeek.thursday'),
        this.$t('shared.daysOfTheWeek.friday'),
        this.$t('shared.daysOfTheWeek.saturday'),
        this.$t('shared.daysOfTheWeek.sunday'),
      ] as CalendarData['daysOfTheWeek'],
      months: [
        this.$t('shared.months.january'),
        this.$t('shared.months.february'),
        this.$t('shared.months.march'),
        this.$t('shared.months.april'),
        this.$t('shared.months.may'),
        this.$t('shared.months.june'),
        this.$t('shared.months.july'),
        this.$t('shared.months.august'),
        this.$t('shared.months.september'),
        this.$t('shared.months.october'),
        this.$t('shared.months.november'),
        this.$t('shared.months.december'),
      ],
      selectedDate1: null,
      selectedDate2: null,
    } as CalendarData;
  },
  mounted() {
    this.selectedDate1 = this.selectableRange.fromDate;
    this.selectedDate2 = this.selectableRange.toDate;

    this.selectedMonth = Number(this.selectedDate1.split('-')[1]) - 1;
    this.selectedYear = Number(this.selectedDate1.split('-')[0]);

    this.calendar = this.createCalendar(this.selectedYear, this.selectedMonth);
  },
  methods: {
    isDateInOrdersRange(currentDate: string) {
      if (!this.selectedDate1 || !this.selectedDate2) {
        return false;
      }

      return currentDate <= this.selectedDate2 && currentDate >= this.selectedDate1;
    },
    isDaySelected(day: string) {
      return Boolean(day) && (this.selectedDate1 === day || this.selectedDate2 === day);
    },
    isDayWithSales(date: string) {
      return this.highlightDates.includes(date);
    },
    isDateAvailable(date: string) {
      return this.allowOnlyForward ? date >= this.todaysDate : date <= this.todaysDate;
    },
    createCalendar(year: number, month: number) {
      const date = new Date(year, month);
      const dateMonth = month + 1;
      const daysInAMonth = new Date(year, dateMonth, 0).getDate();

      /**
       * Spaces for the first row from Monday till the
       * first day of the month _ _ _ 1 2 3 4
       **/
      const table = Array(this.getWeekDay(date)).fill('');

      for (let i = 1; i <= daysInAMonth; ++i) {
        table.push(
          `${year}-${dateMonth < 10 ? '0' + dateMonth : dateMonth}-${i < 10 ? '0' + i : i}`,
        );
      }

      return table;
    },
    getWeekDay(date: Date) {
      const day = date.getDay(); // get day number from 0 (monday) to 6 (sunday)

      return day === 0 ? 6 : day - 1;
    },
    handleChangeMonth(side: 'left' | 'right') {
      if (side === 'left') {
        if (this.selectedMonth === 0) {
          this.selectedMonth = 11;
          this.selectedYear -= 1;
        } else {
          this.selectedMonth -= 1;
        }
      } else {
        if (this.selectedMonth === 11) {
          this.selectedMonth = 0;
          this.selectedYear += 1;
        } else {
          this.selectedMonth += 1;
        }
      }
      this.calendar = this.createCalendar(this.selectedYear, this.selectedMonth);
    },
    handleThisYearClick() {
      const dateNow = new Date();

      this.selectedYear = dateNow.getFullYear();

      this.selectedDate1 = new Date(dateNow.getFullYear(), 0, 1).toLocaleDateString('fr-CA');
      this.selectedDate2 = new Date(dateNow.getFullYear(), 12, 0).toLocaleDateString('fr-CA');

      this.handleClose(true);
    },
    handleMonthClick(isCurrent: boolean) {
      const dateNow = new Date();
      let dateFrom = null;
      let dateTo = null;

      if (isCurrent) {
        dateFrom = new Date(dateNow.getFullYear(), dateNow.getMonth(), 1);
        dateTo = new Date(dateNow.getFullYear(), dateNow.getMonth() + 1, 0);
      } else {
        dateFrom = new Date(dateNow.getFullYear(), dateNow.getMonth() - 1, 1);
        dateTo = new Date(dateNow.getFullYear(), dateNow.getMonth(), 0);
      }

      this.selectedDate1 = dateFrom.toLocaleDateString('fr-CA');
      this.selectedDate2 = dateTo.toLocaleDateString('fr-CA');

      this.handleClose(true);
    },
    showOnlyDayFromDate(date: string) {
      return date.split('-')[2];
    },
    handleCalendarDayClick(date: string) {
      //This shouldn't be needed but touch-events is not working for some reason.
      if (!this.isDateAvailable(date)) {
        return;
      }

      if (!this.isRangeCalendar) {
        this.selectedDate1 = date;
        this.selectedDate2 = date;

        this.handleClose(true);
      }

      if (this.selectedDate2) {
        this.selectedDate1 = date;
        this.selectedDate2 = null;

        return;
      }

      if (this.selectedDate1) {
        if (this.selectedDate1 > date) {
          this.selectedDate2 = this.selectedDate1;
          this.selectedDate1 = date;
        } else {
          this.selectedDate2 = date;
        }

        const calendar = this.$refs.calendar as HTMLElement;
        calendar.scrollTo({ top: this.calendarBodyHeight, behavior: 'smooth' });
        return;
      }

      this.selectedDate1 = date;
    },
    handleClose(shouldSubmit = false) {
      if (shouldSubmit) {
        this.$emit('close', { fromDate: this.selectedDate1, toDate: this.selectedDate2 });
      }

      this.$emit('close');
    },
    formatDate(date: string) {
      return date.replaceAll('-', '/');
    },
  },
  computed: {
    parsedDaysOfTheWeek() {
      return this.daysOfTheWeek.map((weekDay) => weekDay.substring(0, 2));
    },
    todaysDate() {
      return this.allowOnlyForward
        ? this.selectableRange.fromDate
        : new Date().toLocaleDateString('fr-CA');
    },
    availableYears() {
      return Array.from({ length: new Date().getFullYear() + 3 - 2020 }, (_, i) => 2020 + i);
    },
    buttonPresets() {
      return [
        {
          label: this.$t('shared.inputs.calendar.pastMonth'),
          event: this.handleMonthClick.bind(undefined, false),
        },
        {
          label: this.$t('shared.inputs.calendar.thisMonth'),
          event: this.handleMonthClick.bind(undefined, true),
        },
        {
          label: this.$t('shared.inputs.calendar.thisYear'),
          event: this.handleThisYearClick,
        },
      ];
    },
    calendarBodyHeight() {
      const calendar = this.$refs.calendar as HTMLElement;
      return calendar.scrollHeight;
    },
  },
  watch: {
    selectedMonth() {
      this.calendar = this.createCalendar(this.selectedYear, this.selectedMonth);
    },
    selectedYear() {
      this.calendar = this.createCalendar(this.selectedYear, this.selectedMonth);
    },
  },
});
</script>

<style lang="scss" scoped>
$orange: var(--brand-tertiary);

.calendar {
  @include brand-font-m;

  --calendar-day-height: 36px;
  /**
  * Without a fixed height set, some screens or browsers
  * with too much zoom wouldn't be able to see the whole
  * component
  */
  width: fit-content;

  margin: 0 auto;
  padding: var(--spacing-xs) var(--spacing-s);

  &__wrapper {
    max-height: calc(70vh - var(--header-height));

    border-radius: var(--spacing-3xs);
    box-shadow: 1px 2px 7px 0px rgba(var(--brand-primary-rgb), 0.3);

    overflow: auto;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: center;

    height: var(--input-height);
    margin-bottom: var(--spacing-s);
  }
  &__header-selectors {
    display: flex;
    justify-content: center;
    gap: var(--spacing-xs);
  }
  &__header-selector {
    background-color: transparent;

    text-align: right;

    border: none;
  }
  &__header-icon {
    cursor: pointer;
    
    height: 1.5rem;
    stroke: var(--brand-black);
    stroke-width: 2px;

    &--left {
      margin-right: var(--spacing-xs);
      transform: rotate(90deg);
    }

    &--right {
      margin-left: var(--spacing-xs);
      transform: rotate(270deg);
    }
  }
  &__body {
    display: grid;
    grid-template-columns: repeat(7, var(--calendar-day-height));

    font-size: 0.85rem;
    text-align: center;
  }
  &__week-day {
    margin-bottom: var(--spacing-xs);

    font-weight: 800;
    font-size: 0.9rem;

    color: var(--brand-primary);
  }
  &__day {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    aspect-ratio: 1;

    &--disabled {
      color: var(--secondary-text-2);

      pointer-events: none;
      touch-action: none;
      cursor: not-allowed;
    }
    &--in-range {
      background-color: var(--brand-background);
      color: var(--brand-secondary);
    }
    &--active {
      border-radius: var(--spacing-3xs);

      background-color: var(--brand-secondary);
      color: var(--color-white);
    }
    &--has-sales {
      position: relative;

      &:after {
        position: absolute;
        bottom: var(--spacing-3xs);
        left: 50%;

        transform: translateX(-50%);

        color: $orange;

        font-size: 0.5rem;
        content: '●';
      }
    }
  }
  &__footer {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin-top: var(--spacing-xxs);
  }
  &__footer-legend {
    display: flex;
    align-self: flex-end;

    margin-top: var(--spacing-xs);

    font-weight: 300;
    font-size: 0.9em;
  }
  &__footer-icon {
    margin-right: var(--spacing-xxs);

    color: var(--brand-tertiary);
  }
  &__footer-presets,
  &__footer-actions {
    display: flex;
    width: 100%;

    margin-top: var(--spacing-xxs);
  }
  &__footer-actions {
    margin-top: var(--spacing-xs);
  }
  &__footer-button {
    cursor: pointer;

    width: 100%;
    height: var(--calendar-day-height);

    border: 1px solid var(--input-border);
    border-radius: var(--spacing-3xs);
    background-color: var(--grey-background);

    box-sizing: border-box;

    &--primary {
      background-color: var(--brand-secondary);
      color: var(--color-white);
    }

    &:not(:last-child) {
      margin-right: var(--spacing-xxs);
    }
  }
  &__footer-selected {
    margin-top: var(--spacing-xs);

    font-size: 0.9rem;
  }

  @include breakpoint-to('mobile') {
    &--is-inside-modal {
      padding: var(--spacing-xxs);
    }
  }

  @include breakpoint-from('mobile') {
    --calendar-day-height: 45px;
  }

  @include breakpoint-from('tablet') {
    --calendar-day-height: 40px;

    &__wrapper {
      max-height: calc(60vh - var(--header-height));
    }
  }
}
</style>
