<template>
  <div class="optimizations-dashboard">
    <transition name="fade" mode="out-in">
      <component
        :class="[
          'optimizations-dashboard__layout',
          { 'optimizations-dashboard__layout--authenticated': isAuthenticated },
        ]"
        :is="layout"
        v-bind="{ meta: $route.meta }"
      >
        <router-view />
      </component>
    </transition>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { debounce } from '@/utils/timers/timers';
import { setupLanguage } from '@/plugins/i18n';

export default defineComponent({
  name: 'App',
  mounted() {
    this.setNewViewPort();
    setupLanguage();

    window.addEventListener('resize', debounce(this.setNewViewPort));
  },
  computed: {
    layout() {
      return this.$route.meta.layout || 'blank-layout';
    },
    isAuthenticated() {
      return this.$route.meta.layout === 'authenticated-layout';
    },
  },
  methods: {
    setNewViewPort() {
      this.$store.commit('setNewViewPort', window.innerWidth);
    },
  },
});
</script>

<style lang="scss">
@import '@/assets/styles/utils/_normalize.scss';
@import '@/assets/styles/utils/_global';

.optimizations-dashboard {
  height: 100%;

  &__layout {
    display: flex;

    &--authenticated {
      flex-direction: column;
    }
  }

  @include breakpoint-from('smallDesktop') {
    &__layout {
      height: 100vh;
    }
  }
}

body::-webkit-scrollbar {
  width: 2px; /* width of the entire scrollbar */
  height: 4px;
}
body::-webkit-scrollbar-track {
  background: transparent; /* color of the tracking area */
}
body::-webkit-scrollbar-thumb {
  background-color: #232859; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
