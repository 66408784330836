// TODO: Check better way to do this
import { store } from '@/store/v1';
import { getTranslation, replaceTemplate } from './translation';
import { useLanguageStore } from '@/store/v2';
import { TranslatedObject } from '@/routes/routesInterface';


export const setupLanguage = (_lang?: string): void => {
  const languageStore = useLanguageStore()

  let lang = _lang ?? navigator.language;
  if (lang === 'pt-BR' || lang === 'pt-PT' || lang === 'pt') {
    lang = 'pt-PT';
  } else if (lang === 'es-ES' || lang === 'es') {
    lang = 'es-ES';
  } else {
    lang = 'en-GB';
  }

  store.state.selectedLanguage = lang as keyof TranslatedObject;

  languageStore.changeLanguage(lang)
};

export const localizeTemplate = (labelPath: string, templateStrings?: string[]): string => {
  // Get translation
  let result = getTranslation(labelPath);

  if (templateStrings === undefined) return result;

  // Replace template strings
  result = replaceTemplate(result, templateStrings);

  return result;
};
